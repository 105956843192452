body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    background: #ecf0f5;
    overflow-x: hidden;
}

/* Privacy preferences floating button styling */

#onetrust-consent-sdk #ot-sdk-btn-floating.ot-floating-button {
    width: 30px;
    height: 30px;
}

.ot-floating-button__open,
.ot-floating-button__close {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ot-floating-button__open svg {
    margin-left: 1px;
}

.ot-floating-button__open svg,
.ot-floating-button__back svg {
    zoom: 50%;
}

/* Fix graphic error with gainsight modal */
body.apt-vex.vex-open {
    overflow-x: hidden !important;
}

/*OVERRIDE ANT BLEAH */
label {
    font-size: 16px;
}
.Root-Icon {
    border: 0;
}
.ant-avatar-string {
    position: relative !important;
    left: 0 !important;
}
.ant-btn,
.ant-input,
.ant-card,
.ant-popover {
    font-size: 1em;
}
.ant-layout-sider-children,
.ant-layout-header {
    z-index: 9;
}
.ant-layout-sider.ant-layout-sider-collapsed {
    z-index: 999;
}

.ant-layout h1 {
    font-size: 2em;
}

.ant-input-affix-wrapper .ant-input-suffix {
    right: 15px;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
    padding: 8px;
}

tr.ant-table-expanded-row td > .ant-table-wrapper {
    margin: -9px -9px -9px;
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: #595959;
}

.ant-table {
    font-size: 16px;
}
.ant-alert-with-description .ant-alert-message {
    font-size: 16px;
}
.ant-alert-description {
    font-size: 14px;
}

.ant-menu-item,
.ant-menu-item a,
.ant-menu-submenu-title {
    color: #fff !important;
    text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.24);
}

.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
    background-color: #5a6872;
    color: #fff !important;
    text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.24);
}

.ant-menu-sub {
    background: #152935 !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-image: linear-gradient(315deg, #00c3ea, #0090d1);
    color: #fff !important;
    text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.24);
}

.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-submenu-disabled > a {
    color: #fff !important;
}

/* END OVERRIDE ANT BLEAH */

.no-overflow {
    overflow: hidden;
}
#_elev_io ._xy6qs {
    top: inherit !important;
    bottom: 100px;
}

.uv-icon.uv-bottom-right {
    right: 0 !important;
    bottom: 110px !important;
}

/* TODO rimuovere */

/* .vertical-text {
    transform: rotate(-90deg);
    white-space: nowrap;

    width: 200px;
    height: 200px;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 0;
    left: 5px;
    bottom: 20px;
    right: 0;
}*/
.row-responsive {
    margin: 20px;
    text-align: center;
}
.col-responsive {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    display: inline-block;
}

.labelForm {
    padding: 4px 7px;
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-right: 0px;
    border-radius: 4px;
    position: relative;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    height: 32px;
    padding-top: 10px;
    margin-top: 4px;
    width: 150px;
}

.selectInput .ant-select-selection--single {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 32px;
    padding-top: 3px;
    margin-top: 4px;
    margin-right: 5px;
}
.dateInput .ant-calendar-picker-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 32px;
    padding-top: 3px;
    margin-top: 4px;
}
.radioInput .ant-radio-button-wrapper:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 32px;
    padding-top: 3px;
    margin-top: 4px;
}
.treeInput .ant-select-selection--multiple {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    min-height: 32px;
    padding-top: 3px;
    margin-top: 4px;
}
.uv-icon-trigger {
    visibility: hidden;
}

.exit-item-create {
    position: absolute;
    top: 103px;
    z-index: 5;
    right: 45px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 20px;
}

@media screen and (max-width: 1536px) {
    .exit-item-create {
        top: 90px;
        right: 20px;
    }
}
